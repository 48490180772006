.info-nova-versao {
  background-color: #fff3cd;
  border: solid 1px #ffe69c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #664d03;
  margin: 7px auto;
  padding: 5px 15px;
  text-align: center;
}

.info-nova-versao.clicavel {
  cursor: pointer;
}

.info-nova-versao.clicavel:hover {
  background-color: #feedb6;
}

.info-nova-versao .submit-button {
  max-width: unset;
}