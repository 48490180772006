.content-detalhes{
  width: 900px;
}

.submit-button {
  padding: 8px 16px;
  background-color: #1d4e82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.labelDetalhes {
  font-size: 16px;
  color: #4B5463;
  font-weight: 400;
}

.grid-inline-detalhes {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.grid-collum {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
}

.ticket__detalhes {
  height: 500px;
  overflow: auto;
  margin-bottom: 10px;
}
span {
  color:#1D2129;
  font-weight:bold;
}

@media only screen and (max-height: 750px) {
  .modal {
      height: 680px;
      overflow: auto;
  }
  .ticket__detalhes {
    height: auto;
    overflow: hidden;
  }
}