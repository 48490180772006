.card-dropzone {
  width: 500px;
  height: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone {
  width: 400px;
  height: 300px;
  border: 2px dashed #d1d1d1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
}

.dropzone p {
  font-weight: 500;
}

.card-dropzone-las-update {
  font-size: small;
  color: gray;
}

.selectionzone {
  position: relative;
  width: 400px;
  height: 300px;
  border: 2px dashed #d1d1d1;
  background-color: #f9f9f9;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
}

.selectionzone label {
  font-weight: bold;
}

.selectionzone .selectionzone-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.selectionzone .submit-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  background-color: #1d4e82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.selectionzone .submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.selectionzone .remover-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  background-color: #ff0000;
  border: none;
  cursor: pointer;
}

.button-atualizar-ambiente {
  background-color: #fff3cd;
  border: solid 1px #ffe69c;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #664d03;
  cursor: pointer;
  display: block;
  margin: auto;
  padding: 20px;
}

.button-atualizar-ambiente:hover {
  background-color: #feedb6;
}