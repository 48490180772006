thead {
  position: sticky;
  top: 0; 
  bottom: 10px;
}
thead tr {
  height: 50px;
  background: #f8f9fa;
}

thead tr th {
  color: #6f6f6f;
  padding:0 10px;
}
