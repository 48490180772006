.table-body {
  padding: 0 10px;
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  background: white;
}

.table-body tr {
  height: 50px;
  color: #6f6f6f;
}

.table-body tr td {
  padding: 0 10px;
  background: white;
}

.table-body tr:hover {
  color: black;

  td {
    background: #f8f9fa;
  }
}