.selecionar-licenciado-itens {
  background-color: white;
  padding: 20px;
  list-style-type: none;
  text-align: center;
}

.selecionar-licenciado-label {
  display: inline-block;
  margin-top: 20px;
}

.selecionar-licenciado-itens-carregando {
  border: 0;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.selecionar-licenciado-item {
  border: 1px solid #dedede;
  border-radius: 8px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 100%;
}

.selecionar-licenciado-item:not(:last-child) {
  margin-bottom: 10px;
}

.selecionar-licenciado-item:hover {
  background-color: #ffdada;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}