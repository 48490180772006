.content-modulo {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
}

.content-modulo h1 {
  font-size: 40px;
  margin-top: 40px;
  color: #6C7381;
}

.content-modulo-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.content-collum {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.content-collum label {
  size: 32px;
  font-weight: 800;
  font-size: 45px;
  text-align: left;
  font-family: Nunito Sans;
  line-height: 40.96px;
}

.content-modulo-collum {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 450px;
  height: 290px;
  margin: 20px 50px;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 4px 7px 0px #0000001A;
  border: 0;
  transition: 1s;
}

.content-modulo-collum .img {
  position: absolute;
  width: 300px;
  height: 250px;
  margin-left: 9px;
  transition: 1s;
}

.content-modulo-collum .content-collum {
  padding-top: 25px;
  transition: padding-top 1s;
}

.content-modulo-collum .content-collum span {
  margin-top: 0;
  transition: margin-top 1s;
}

.content-modulo-collum#pessoal {
  background: #EEF5FF;

  .content-collum {
    span {
      color: #604EE9;
    }

    label {
      color: #604EE9;
    }
  }
}

.content-modulo-collum#rh {
  background: #FFEBD6;

  .content-collum {
    span {
      color: #FB6B2B;
    }

    label {
      color: #FB6B2B;
    }

  }

  .content-collum .img {
    width: 299px;
  }
}

.content-modulo-collum#financeiro {
  background: #EDFDF5;

  .content-collum {
    span {
      color: #409C58;
    }

    label {
      color: #409C58;
    }
  }
}

.content-modulo-collum#pessoal .img {
  background: url('/public/pessoal-bt.png')no-repeat center center / cover;
}

.content-modulo-collum#rh .img {
  background: url('/public/rh-bt.png')no-repeat center center / cover;
}

.content-modulo-collum#financeiro .img {
  background: url('/public/financeiro-bt.png')no-repeat center center / cover;
}

.content-modulo-collum:hover {
  transition: 1s;
  cursor: pointer;

  .content-collum {
    padding-top: 5px;
    transition: 1s;
  }

  .content-collum span {
    margin-top: 30px;
    transition: 1s;
  }
}

.content-modulo-collum#pessoal:hover {
  background: #D6E7FF;
  border: 1px solid #008BD6;

  .img {
    background: url('/public/pessoal-bn.png')no-repeat center center / cover;
  }
}

.content-modulo-collum#rh:hover {
  background: #FFD6AD;
  border: 1px solid #E67505;

  .img {
    background: url('/public/rh-bn.png')no-repeat center center / cover;
  }
}

.content-modulo-collum#financeiro:hover {
  background: #D4FCE9;
  border: 1px solid #08A350;

  .img {
    background: url('/public/financeiro-bn.png')no-repeat center center / cover;
  }
}

.content-modulo-collum-disabled {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 450px;
  height: 290px;
  margin: 20px 50px;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 4px 7px 0px #0000001A;
  border: 0;
  transition: 1s;
  background-color: #D3D3D3;
  opacity: 40%;

  span {
    color: #6C7381;
  }

  label {
    color: #6C7381;
  }

}

.content-modulo-collum-disabled :hover {
  cursor: pointer;
}

.content-modulo-collum-disabled#rh .img {
  background: url('/public/rh-bt.png')no-repeat center center / cover;
}

.content-modulo-collum-disabled#pessoal .img {
  background: url('/public/pessoal-bt.png')no-repeat center center / cover;
}

.content-modulo-collum-disabled#financeiro .img {
  background: url('/public/financeiro-bt.png')no-repeat center center / cover;
}

.content-modulo-collum-disabled .img {
  filter: grayscale(100%);
  position: absolute;
  width: 300px;
  height: 250px;
  margin-left: 9px;
  transition: 1s;
}
