.formulario {
  background: #fff;
  padding: 30px;
  border-radius: 16px;
  margin-top: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.formulario__corpo {
  margin-top: 30px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.formulario__corpo--input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 10px;
  width: auto;
}

.formulario__corpo--select {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 10px;
  width: auto;
}

.formulario__button {
  padding: 8px 16px;
  background-color: #1d4e82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .formulario {
      min-width: auto;
  }
}

@media only screen and (max-width: 1096px) {
  .formulario {
    margin-top: 0;
  }
}