.upload {
  display: flex;
  padding: 10px;
  background-color: #f2f2f2;
  width: auto !important;
  border-radius: 5px;
}

.agrupa-imagens{
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
}

.icon-svg {
  display: none;
  position: absolute;
  pointer-events: none;
 }
 
 .agrupa-imagens:hover .icon-svg {
  display: block;
 }

.upload img {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 5px;
}

.upload img:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  opacity: 0.1;
}

.button-upload {
  margin-right:5px;
  margin-top: 5px;
  width: auto;
  height: auto;
  display: inline-block;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #737373;
  cursor: pointer;
}

.button-upload:hover {
  border: 1px solid red;
  color: #000;
}