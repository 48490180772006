.container-ticket {
  margin-top: 40px;
  padding: 40px;
  max-width: 1220px;
  min-width: 1220px;
  width: auto;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow:auto; 
  text-align: left;
  justify-content: end;
}

.submit-button {
  padding: 8px 16px;
  border: none;
  background-color: #1d4e82;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.text-input{
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 2px;
  width: auto;
}

.company-label {
  font-size: 1rem;
  color: #495057;
  font-weight: 600;
}

.row{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}