.dynamic-columns-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-column-button {
  margin-right: 5px;
  margin-bottom: 8px;
  padding: 6px 10px;
  background-color: #cacaca;
  border: none;
  border-radius: 4px;
  color: #6f6f6f;
  cursor: pointer;
  font-size: 16px;
}

.input-fields {
  margin-left: 85px;
  flex-direction: row;
  display: flex;
}

.company-billing-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  width: 150px;
  height: 22px;
  font-size: 16px;
  margin-bottom: 2px;
  color: #202020;
}

.year-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 2px;
}

.year-input {
  width: 70px;
}

.submit-button {
  padding: 8px 16px;
  background-color: #1d4e82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #6f6f6f;
}

.grid {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.grid-empresa {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: flex-end;
  max-width: 500px;
}


.month-names {
  display: flex;
  flex-direction: column;
}

.month-name {
  padding: 6px;
  border-radius: 4px;
  height: 20px;
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
  color: #6f6f6f;
  text-align: right;
}

.billings-area {
  flex-grow: 1;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  max-width: 615px;
}

.column-container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  padding: 8px 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.year-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 21px;
  color: #6f6f6f;
}

.remove-column-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  background-color: #ff0000;
  border: none;
  cursor: pointer;
  margin-right: 8px;
}

.faturamento .toolbar {
  text-align: center;
  margin: 10px 0;
}

.faturamento .submit-button:not(:last-child) {
  margin-right: 10px;
}

.dynamic-lines-container {
  min-width: 500px;
}

.text-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 2px;
  width: auto;

}

@media only screen and (max-width: 800px) {
  .billings-area {
    display: flex;
    flex-wrap: wrap;
  }
}