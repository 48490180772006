.carregando-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0, 0);
  border: 0;
  box-shadow: none;
}

.painel-container {
  overflow: hidden;
  position: absolute;
  top: 78px;
  bottom: 0;
  width: 100%;
  border: 0;
  z-index: -1;
  
}

.painel-container .painel {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
}

.painel-container .painel iframe {
  border: 0;
}

.painel__iconImg {
  bottom: 0;
  left: 30px;
  width: 45px;
  height: 45px;
}

@media only screen and (max-width: 985px) {
  .painel-container {
    margin-top: 30px;
  }
}