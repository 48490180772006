.menu-container {
    position: relative;
}

.hamburger-icon {
    padding-right: 20px;
    z-index: 2000;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 30px;
    cursor: pointer;
}

.hamburger-line {
    width: 100%;
    height: 2px;
    background-color: #333;
    margin-bottom: 5px;
    transition: transform 0.3s ease;
}

.menu {
    position: fixed;
    top: 74px;
    left: -300px;
    bottom: 0;
    overflow-y: auto;
    width: auto;
    border-right: 1px solid #f1f1f1;
    background-color: #fff;
    list-style: none;
    margin: 0;
    transition: left 0.3s ease;
    box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.2);
    padding-left: 0px;
}

.menu.open {
    left: 0px;
}

.menu li {
    margin-bottom: 5px;
}

.menu a,
.menu .usuario-logado:not(:has(a)) {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: large;
    display: block;
    padding: 15px;
    padding-left: 40px;
}

.menu a:hover {
    background-color: #ffdada;
}

.menu-user-data {
    display: none;
    background-color: #ededed;
}

.movidesk-menu-item {
    display: none;
}

.divisao {
    display: none;
    color: #DCDFE4;
}

.divisao-fixa {
    color: #DCDFE4;
}

.menu-user-data .usuario-logado .extra {
    font-weight: normal;
}

@media only screen and (max-width: 500px) {
    .menu-user-data {
        display: block;
    }

}

@media only screen and (max-width: 670px) {
    .movidesk-menu-item {
        display: block;
    }

    .divisao {
        display: block;
    }
}