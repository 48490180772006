.default{
    width: auto;
    height: 30px;
    display: inline-block;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #737373;
    margin: 0 0 5px 10px;
    cursor: pointer;
}

.default:hover {
    border: 1px solid red;
    color: #000;
}