.input-search {
  padding: 0%;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: absolute;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: white;
}

.input-search li {
  padding: 10px;
  list-style-type: none;
  margin-bottom: 5px;
}

.input-search li:hover {
  cursor: pointer;
  background-color: #ccc;
}