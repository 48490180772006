.feedback-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding-bottom: 20px;
  z-index: 1;
}

.feedback-container>.feedback {
  display: flex;
  border-radius: 4px;
  padding: 1rem;
  position: relative;
  padding-right: 3rem;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.feedback-container>.feedback>.fechar {
  cursor: pointer;
  font-size: xx-large;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  line-height: 1rem;
  outline: none;
}

.feedback-container>.feedback>.fechar:focus {
  outline: 1px solid;
}

.feedback-container>.feedback-sucesso {
  background-color: #d1e7dd;
  border: solid 1px #a3cfbb;
  color: #0a3622;
  justify-content: center;
}

.feedback-container>.feedback-erro {
  background-color: #f8d7da;
  border: solid 1px #f1aeb5;
  color: #58151c;
  justify-content: center;
}

.feedback-container>.feedback-aviso {
  background-color: #fff3cd;
  border: solid 1px #ffe69c;
  color: #664d03;
  justify-content: center;
}