.content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 9999;
}

.modal {
  position: fixed;
  background-color: #fff;
  bottom: 50%;
  right: 50%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  justify-content: end;
  transform: translate(50%, 50%);
  padding: 30px;
}

.modal h1 {
  margin-top: 0;
}

@media only screen and (max-height: 700px) {
  .modal {
    height: 650px;
    overflow: auto;
  }
}