.empresa-edicao {
  background: #fff;
  border-radius: 16px;
}

.empresa-edicao__corpo {
  margin-top: 30px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.empresa-edicao__corpo--input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 20px;
  width: auto;
}

.empresa-edicao__button {
  padding: 8px 16px;
  background-color: #1d4e82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}