.usuario-logado a {
  color: inherit;
}

.usuario-logado a:link {
  text-decoration: none;
}

.usuario-logado .dados {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.usuario-logado .extra {
  font-size: 10px;
}