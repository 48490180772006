.container {
  margin-top: 40px;
  padding: 40px;
  max-width: 1220px;
  width: 99%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow:auto; 
  text-align: left;
  justify-content: end;
}

.text-input{
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  font-size: 15px;
  margin-bottom: 2px;
  width: auto;
}

.submit-button {
  padding: 8px 16px;
  background-color: #1d4e82;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.company-label {
  font-size: 1rem;
  color: #495057;
  font-weight: 600;
}

.ticket-body{
  padding-top: 5px;
  margin-bottom: 30px;
}

.grid-inline {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.grid-collum {
  display: flex;
  flex-direction: column;  
  justify-content: flex-end;  
}

.grid-collum label{
  margin-bottom: 4px;
}

textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 100px;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}