.menu__movidesk {
  justify-content: space-around;
  /* Alinha os botões horizontalmente com espaçamento igual */
  align-items: center;
  /* Centraliza verticalmente os botões */
  width: auto;
  height: auto;
  margin-right: 13px;
  padding-top: 10px;
  z-index: 1;
}

.layout__botoes {
  margin-bottom: 5px;
  background-color: transparent;
  position: relative;
  border: none;
  width: auto;
  height: auto;
  text-align: center;
  justify-content: center;
}

.layout__botoes__img {
  color: gray;
  border: none;
  margin-bottom: 6px;
  width: 30px;
  height: 30px;
}

.titulo {
  display: none;
  position: fixed;
  top: 65px;
  font-size: 0.7rem;
  color: #495057;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 1px 6px;
}

.botoes__chat {
  text-align: left;
  display: none;
  padding: 8px;
  z-index: 99999;
  border-radius: 5px;
  height: auto;
  width: 200px;
  position: absolute;
  top: 35px;
  font-size: 0.7rem;
  color: #495057;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.botoes__chat ul {
  height: auto;
  width: auto;
}

.botoes__chat .opcoes {
  color: #495057;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  label {
    cursor: pointer;
  }
}

.botoes__chat .opcoes:hover {
  background-color: #ffdada;
  label{
    color: red;  
  }
}

.layout__botoes__img:hover {
  color: red;
}

.layout__botoes:hover .titulo,
.layout__botoes:hover .botoes__chat {
  display: block;
}
