.action__button {
  padding: 0;
  background-color: transparent;
  position: relative;
  border: none;
  width: auto;
  height: auto;
  text-align: center;
  justify-content: center;
}

.action__button--img{
  color: gray;
  border: none;
  margin-bottom: 6px;
  width: 13px;
  height: 13px;
}

.action__button :hover {
  cursor: pointer;
}