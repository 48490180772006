.authenticator-content {
  overflow-x: auto;
  background-image: url(../../../public/background.jpg);
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 320px;
  background-position-y: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-height: 100%;
  margin: 0;
  justify-content: flex-start;
}

.singIn {
  margin-left: 30px;
}

:root {
  --amplify-components-button-color: red;
  --amplify-internal-button-background-color: red;
  --amplify-components-authenticator-router-border-color: white;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-authenticator-router-background-color: none;
  --amplify-components-authenticator-router-border-width: none;
  --amplify-components-authenticator-router-border-style: none;
}

.amplify-button--link {
  --amplify-internal-button-background-color: white;
  color: red;
}

.amplify-button--primary {
  --amplify-internal-button-background-color: red;
}

.amplify-button--primary:hover {
  --amplify-internal-button-background-color: rgb(129, 55, 55);
}

.amplify-input {
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
}

.amplify-input:focus {
  border-color: #66afe9;
  outline: 0;
}

.login__notificacao {
  justify-content: center;
  display: flex;
  text-align: left;
  margin-top: 30px;
}

.login__notificacao--body {
  width: 800px;
  background: #fff;
  color: #6C7381;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 7px 0px #0000001A;
}

.login__notificacao h4 {
  margin: 0
}