a.volta {
  color: #666666;
  padding-right: 4px;
  padding-top: 1px;
  transition: 0.2s;
  border-radius: 50px;
  display: inline-block;
  font-size: 27px;
  line-height: 29px;
  text-decoration: none;
  width: 33px;
  height: 33px;
  text-align: center;
  margin-right: 4px;
  font-weight: bold;
}

a.volta:hover {
  color: red !important;
  transition: 0.2s;
  background-color: #ffdada;
}

a.volta:visited {
  color: #666666;
}