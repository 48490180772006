.painel {
  justify-content: center;
  background-color: #fff;
  z-index: -1;
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}

.painel__lista__row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
}

.painel__lista__column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-self: stretch;
  flex: 1;
}

.paineis {
  left: 0;
  width: 100%;
  max-width: 770px;
}

.painel__link-painel {
  display: inline-block;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 280px;
  padding: 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #737373;
  margin: 0 0 23px 77px;
  cursor: pointer;
}

.lista-de-paineis_background {
  opacity: 20%;
  z-index: -100;
  max-width: 100%;
}

.painel__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.painel__link-painel:hover {
  border: 1px solid red;
  color: #000;
}

.painel .paineis .painel__link-painel div {
  display: flex;
  align-items: center;
  cursor: pointer
}

@media only screen and (max-width: 760px) {
  .paineis {
    transform: none;
  }

  .painel__loading {
    transform: translate(-50%, 0);
    top: 70px;
  }
}

@media only screen and (max-width: 1480px) {
  .lista-de-paineis_background {
    position: absolute;
    right: 0;
  }
  .painel__lista__column {
    width: auto;
    flex: 1;
  }
}