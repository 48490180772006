.content-container {
  margin-top: 40px;
  padding: 40px;
  max-width: 1024px;
  width: 99%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow:auto; 
}

@media only screen and (max-width: 800px) {
  .content-container {
      min-width: auto;
  }
}

@media only screen and (max-width: 1096px) {
  .content-container {
    margin-top: 0;
  }
}